<template>
  <Layout>
    <div class="orderdetails cnt">
      <div style="float: right; text-align: right"
           v-if="order.articles.length">
        <v-chip v-if="order.state === 'new' || (order.state === 'delivered' && selectionChanged)"
                color="orange"
                text-color="white"
                @click="saveOrderState(order, 'kitchen')">
          Bestellung an Küche/Buffet
        </v-chip>
        <v-chip v-if="order.state === 'kitchen' && !selectionChanged"
                color="grey"
                text-color="white"> In der Küche/Buffet </v-chip>
        <v-chip v-if="order.state === 'kitchen' && selectionChanged"
                color="orange"
                text-color="white"
                @click="saveOrderAction(order)">
          Küche/Buffet aktualisieren
        </v-chip>
        <v-chip v-if="order.state === 'delivered' && !selectionChanged"
                color="grey"
                text-color="white"> Ausgeliefert </v-chip>
      </div>
      <div style="float: right; margin-right: 1rem; text-align: right"
           v-if="order.articles">
        <v-chip v-if="order.state === 'delivered' && order.sumopen === 0"
                color="orange"
                text-color="white"
                @click="archiveOrder(order)">
          Archivieren
        </v-chip>
      </div>
      <h1>{{ $t("order.title") }} {{ order.table.name }}</h1>

      <div v-if="loading"
           class="text-xs-center">
        <v-progress-circular :size="50"
                             color="primary"
                             indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-flex>
          <v-text-field v-model="order.name"
                        :label="$t('order.description')"></v-text-field>
        </v-flex>

        <v-row>
          <v-col md="5"
                 lg="4">
            <div class="orderPositions">
              <h2 class="titleblock">
                <span class="group pa-2"> <v-icon color="orange">attach_money</v-icon>{{ $t("order.open") }} </span>
              </h2>
              <v-list dense
                      v-if="
                        order.articles.filter((art) => {
                          return art.count > art.paid;
                        }).length > 0
                      ">
                <div class="orderGroup"
                     v-for="article in order.articles.filter((art) => {
                      return art.count > art.paid;
                    })"
                     :key="article.articleId">
                  <div class="orderOptions">
                    <span class="group pa-2">
                      <v-icon @click="addArticle(article.article)">add</v-icon>
                      <v-icon @click="removeArticle(article.article)">remove</v-icon>
                    </span>
                  </div>
                  <div :class="['badge']"
                       :style="'border: none; background-color: ' + article.article.articlecategory.color"
                       @click="addToTemp(article)">
                    {{ article.count - article.paid }}
                  </div>
                  <div v-if="article.temp > 0"
                       class="badge temp">
                    {{ article.temp }}
                  </div>
                  <div class="orderDesc">
                    <span><strong>{{ article.article.name }}</strong></span>
                    <br /><small>
                      {{
                        $t("order.articleGroupDetail", {
                          total: (article.count - article.paid) * article.article.price,
                          count: article.count - article.paid,
                          price: article.article.price,
                        })
                      }}</small>
                  </div>
                </div>
              </v-list>
              <div class="text-lg-right d-md-none">
                <v-btn dark
                       fab
                       top
                       right
                       color="blue lighten-3"
                       class="inlineActionFloat"
                       @click="drawer = !drawer">
                  <v-icon>add</v-icon>
                </v-btn>
              </div>
              <div class="orderTot">
                {{ $t("order.openTotal", { total: order.sumopen }) }}
                <div v-if="
                  order.articles.filter((art) => {
                    return art.temp;
                  }).length > 0
                "
                     style="color: #0000ff">
                  {{ $t("order.tempTotal", { total: order.sumtemp }) }}
                </div>
              </div>
              <div v-if="
                !selectionChanged &&
                order.articles.filter((art) => {
                  return art.count > art.paid;
                }).length > 0
              ">
                <v-btn v-if="
                  order.articles.filter((art) => {
                    return art.temp;
                  }).length > 0
                "
                       color="info"
                       @click="markedPaid()">{{ $t("order.markSelectedAsPaid") }}</v-btn>
                <v-btn v-else
                       color="success"
                       @click="markedAllPaid()">{{ $t("order.markAllAsPaid") }}</v-btn>
              </div>
            </div>

            <div class="orderPositions paid"
                 v-if="
                  order.articles.filter((art) => {
                    return art.paid > 0;
                  }).length > 0
                ">
              <h2 class="titleblock">
                <span class="group pa-2"> <v-icon color="orange">money_off</v-icon>{{ $t("order.paid") }} </span>
              </h2>
              <v-list dense
                      class="paidlist">
                <div class="orderGroup"
                     v-for="article in order.articles.filter((art) => {
                      return art.paid > 0;
                    })"
                     :key="article.id">
                  <div :class="['badge']"
                       :style="'border: none; background-color: ' + article.article.articlecategory.color">
                    {{ article.paid }}
                  </div>
                  <div class="orderDesc">
                    <span><strong>{{ article.article.name }}</strong></span>
                    <br /><small>
                      {{
                        $t("order.articleGroupDetail", {
                          total: article.paid * article.article.price,
                          count: article.paid,
                          price: article.article.price,
                        })
                      }}</small>
                  </div>
                </div>
              </v-list>
              <div class="orderTot">
                {{ $t("order.paidTotal", { total: order.sumpaid }) }}
              </div>
            </div>

            <div class="text-lg-right">
              <v-btn v-if="!selectionChanged"
                     dark
                     fab
                     top
                     right
                     color="primary"
                     class="inlineActionFloat"
                     @click="$router.push('/orders')">
                <v-icon>keyboard_backspace</v-icon>
              </v-btn>
            </div>
            <v-btn dark
                   fab
                   top
                   left
                   small
                   color="red"
                   @click="deleteOrder(order)"
                   v-if="order.articles.length == 0 || isAdmin"
                   style="margin-top: 2rem">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>

          <v-col class="d-none d-md-block flatArts">
            <div style="overflow:scroll; height:75vh;">
              <div v-for="(grp, name) in articlesGrouped"
                   :key="name"
                   class="artBlock">
                <h2 class="titleblock px-5">{{ name }}</h2>
                <div class="d-flex flex-wrap">
                  <v-card v-for="article in grp"
                          :key="article.id"
                          class="articleCard ma-2 px-7 py-2"
                          :color="article.articlecategory.color"
                          outlined
                          elevation="1"
                          shaped
                          @click="addArticle(article)">
                    {{ article.name }}
                  </v-card>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-navigation-drawer clipped
                             fixed
                             v-model="drawer"
                             app
                             right
                             disable-resize-watcher>
          <v-list dense>
            <template v-for="(article, i) in articles">
              <v-divider v-if="i > 0 && articles[i - 1].articlecategoryId != articles[i].articlecategoryId"
                         :key="i">asdasd</v-divider>
              <v-list-item :key="article.id"
                           @click="addArticle(article)">
                <v-list-item-action>
                  <v-icon :color="article.articlecategory.color">{{ article.articlecategory.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ article.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <br /><br /><br />
        </v-navigation-drawer>



        <br /><br /><br /><br /><br />
        <ul class="dates">
          <li>
            <span class="type">Created</span> at
            <span class="dt">{{ order.createdat | formatDate(null, "YYYY-MM-DD HH:mm:ss", true) }}</span>
            what is
            <span class="diff">{{ order.createdat | getTimeDiffToNow() }}</span>
            ago
          </li>
          <li>
            <span class="type">Kitchen</span> at
            <span class="dt">{{ order.kitchenat | formatDate(null, "YYYY-MM-DD HH:mm:ss", true) }}</span>
            what is
            <span class="diff">{{ order.kitchenat | getTimeDiff(order.createdat) }}</span>
            later
          </li>
          <li>
            <span class="type">Delivered</span> at
            <span class="dt">{{ order.deliveredat | formatDate(null, "YYYY-MM-DD HH:mm:ss", true) }}</span>
            what is
            <span class="diff">{{ order.deliveredat | getTimeDiff(order.kitchenat) }}</span>
            later
          </li>
          <li class="hidden">UserId: {{ order.userId }}</li>
        </ul>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import EventBus from "../utils/event-bus.js";
import { dt } from "../utils/mixin.js";

export default {
  name: "orderdetails",
  components: {
    Layout,
  },
  mixins: [dt],
  data() {
    return {
      timer: "",
      loading: true,
      drawer: false,
      order: {
        table: {},
        articles: [],
        state: "all",
      },
      articles: [],
      articlesGrouped: {},
      temp: [],
      fab: null,
      selectionChanged: false,
      isAdmin: false,
      saveOnLeave: true,
      visi: {},
    };
  },
  created() {
    this.initialize();
    this.timer = setInterval(this.getState, 5000);
    if (localStorage.user) {
      let u = JSON.parse(localStorage.user);
      this.isAdmin = u.permissions === "admin";
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saveOnLeave || !this.order) {
      next();
    }
    this.saveAction(this.order)
      .then(function () {
        next();
      })
      .catch(function () {
        next(false);
      });
  },
  methods: {
    initialize: function () {
      this.loadOrder();
      this.loadArticles();
    },
    loadOrder: function () {
      axios
        .get(`/api/orders/${this.$route.params.id}`)
        .then((response) => {
          this.order = response.data;
          this.order.articles.forEach((art) => {
            art.temp = 0;
          });
          this.calcSums();
          this.loading = false;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
          //this.loading = false;
          this.saveOnLeave = false;
          this.order = null;
          this.$router.push("/orders");
        });
    },
    loadArticles: function () {
      axios
        .get(`/api/articles`)
        .then((response) => {
          this.articles = response.data;
          this.articlesGrouped = this.groupByArticlesByCategory(this.articles);
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    groupByArticlesByCategory: function (list) {
      return list.reduce(function (rv, x) {
        (rv[x["articlecategory"]["name"]] = rv[x["articlecategory"]["name"]] || []).push(x);
        return rv;
      }, {});
    },
    calcSums: function () {
      this.order.sum = 0;
      this.order.sumopen = 0;
      this.order.sumtemp = 0;
      this.order.sumpaid = 0;
      this.order.articles.forEach((art) => {
        this.order.sum += art.count * art.article.price;
        this.order.sumtemp += art.temp * art.article.price;
        this.order.sumpaid += art.paid * art.article.price;
      });
      this.order.sumopen = this.order.sum - this.order.sumpaid;
    },
    addArticle: function (article) {
      if (this.order.state != 'kitchen' || confirm("Bestellung ändern obwohl sie schon in der Küche ist? Wenn ja, bitte oben aktualisieren wenn fertig.")) {
        let b = this.order.articles.find(function (art) {
          return art.articleId === article.id;
        });
        if (b) {
          b.count++;
        } else {
          this.order.articles.push({
            orderId: this.order.id,
            articleId: article.id,
            article: article,
            paid: 0,
            delivered: 0,
            count: 1,
            temp: 0,
          });
        }
        this.selectionChanged = true;
        this.order.articles.reverse().reverse();
        this.calcSums();
      }
    },
    removeArticle: function (article) {
      if (this.order.state != 'kitchen' || confirm("Bestellung ändern obwohl sie schon in der Küche ist? Wenn ja, bitte oben aktualisieren wenn fertig.")) {
        let b = this.order.articles.find(function (art) {
          return art.articleId === article.id;
        });
        if (b) {
          b.count--;
        }
        this.order.articles = this.order.articles.filter(function (art) {
          return art.count > 0;
        });
        this.selectionChanged = true;
        this.order.articles.reverse().reverse();
        this.calcSums();
      }
    },
    saveOrderState: function (order, state) {
      this.loading = true;
      this.saveAction(order)
        .then((response) => {
          if (response.data.status === "ok") {
            this.saveState(order, state)
              .then((response) => {
                this.loadOrder();
                if (response.data.status === "ok") {
                  EventBus.$emit("showMessage", `Order and state saved`, 500);
                  this.selectionChanged = false;
                }
              })
              .catch((e) => {
                EventBus.$emit("showMessage", e);
                this.loading = false;
              });
          }
          this.loading = false;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
          this.loading = false;
        });
    },
    saveOrderAction: function (order) {
      this.loading = true;
      this.saveAction(order)
        .then((response) => {
          this.loadOrder();
          if (response.data.status === "ok") {
            EventBus.$emit("showMessage", `Order saved`, 500);
            this.selectionChanged = false;
          }
          this.loading = false;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
          this.loading = false;
        });
    },
    saveAction: function (order) {
      if (order) {
        let temp = JSON.parse(JSON.stringify(order));
        delete temp.table;
        delete temp.state;
        temp.articles.forEach((art) => {
          delete art.delivered;
        });
        return axios.post(`/api/orders/${this.$route.params.id}`, temp);
      }
      return Promise.reject();
    },
    getState: function () {
      return axios.get(`/api/orders/${this.order.id}/getstate`).then((orderState) => {
        if (orderState.data) {
          this.order.state = orderState.data.state;
        }
      });
    },
    saveState: function (order, state) {
      return axios.post(`/api/orders/${order.id}/setstate`, { state: state });
    },
    deleteOrder: function (order) {
      if (confirm("Really delete this order??")) {
        clearInterval(this.timer);
        this.saveOnLeave = false;
        axios
          .delete(`/api/orders/${order.id}`)
          .then((response) => {
            if (response.data.status === "ok") {
              EventBus.$emit("showMessage", `Order delete`, 2000);
              this.saveOnLeave = false;
              this.order = null;
              this.$router.push("/orders");
            }
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });
      }
    },
    addToTemp: function (article) {
      article.temp++;
      if (article.temp > article.count - article.paid) {
        article.temp = 0;
      }
      this.order.articles.reverse().reverse();
      this.calcSums();
    },
    markedPaid: function () {
      this.order.articles.forEach((art) => {
        art.paid += art.temp;
        art.temp = 0;
      });
      this.calcSums();
      this.saveAction(this.order)
        .then((response) => {
          if (response.data.status === "ok") {
            EventBus.$emit("showMessage", `Saved`, 1000);
            this.selectionChanged = false;
          }
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    markedAllPaid: function () {
      this.order.articles.forEach((art) => {
        art.paid = art.count;
        art.temp = 0;
      });
      this.calcSums();
      this.saveAction(this.order)
        .then((response) => {
          if (response.data.status === "ok") {
            EventBus.$emit("showMessage", `Saved`, 1000);
            this.selectionChanged = false;
          }
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    archiveOrder: function (order) {
      this.saveState(order, "closed")
        .then(() => {
          this.saveOnLeave = false;
          this.order = null;
          this.$router.push("/orders");
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
  },
};
</script>

<style lang="scss">
.orderdetails {
  .speed-dial {
    position: absolute;

    .btn--floating {
      position: relative;
    }

    .flyOutActionButton {
      margin-bottom: 50px;
    }
  }

  .inlineActionFloat {
    float: right;
  }

  .dates {
    .hidden {
      color: white;
    }

    .type {
      color: green;
    }

    .dt,
    .diff {
      font-weight: bold;
    }

    .diff {
      color: purple;
    }
  }
}

.articleCard {
  border: 2px solid red;
  color: red;
}

.orderPositions {
  &.paid {
    margin: 30px 0;
  }

  .paidlist {
    color: silver !important;

    .badge {
      border: 2px solid grey;
    }
  }

  .orderGroup {
    padding: 3px 0;

    &.payed,
    &.payed span {
      color: grey !important;

      .badge {
        border: 2px solid grey;
      }
    }

    .badge {
      font-size: 16px;
      border: 2px solid #ffffff;
      border-radius: 10px;
      float: left;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-weight: bold;
      margin: 5px 5px 0 0;
      vertical-align: middle;

      &.buffet {
        border: 2px solid orangered;
      }

      &.kitchen {
        border: 2px solid green;
      }

      &.temp {
        border: 2px solid silver;
      }
    }

    .orderDesc {
      margin-left: 50px;
    }

    .orderOptions {
      float: right;

      button {
        height: 36px !important;
        padding: 0 !important;
      }
    }
  }
}

.artBlock {
  margin-bottom: 2rem;
}

.orderTot {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  background: #f1f1f1;
  /* Old browsers */
}

.titleblock {
  border-top: 1px solid silver;
  color: orange;
  background: #f1f1f1;
}

.articleblock {
  display: none;
}

.theme--dark {

  .orderPositions,
  .flatArts {
    .orderTot {
      font-size: 16px;
      font-weight: bold;
      padding: 5px;
      background: #191919;
    }

    .titleblock {
      border-top: 1px solid silver;
      color: orange;
      background: #191919;
    }
  }
}

.menu__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>
