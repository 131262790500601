export default {
  dashboard: {
    title: 'Startseite',
    info: 'Wilkommen auf der startseite der App. Verwende die Navigation links oder click auf die Statistiken um zu den Bestellungen zu gelangen.',
    overviewTitle: 'Bestellungsübersicht',
    totalDesc: 'Du hast {myTotal} von ingesammt {total} bestellungen bearbeitet',
    typeDesc: '{count} Bestellungen in {type}'
  },
  orders: {
    title: 'Bestellungen',
    allTables: 'Alle Tische',
    orderSummary: '{paid} von {total} CHF bezahlt'
  },
  order: {
    title: 'Bestellung',
    description: 'Beschreibung',
    open: 'Offen:',
    openTotal: 'Total offen: {total} CHF',
    paid: 'Bezahlt:',
    paidTotal: 'Total bezahlt: {total} CHF',
    tempTotal: 'Zwischentotal: {total} CHF',
    markSelectedAsPaid: 'Markierte als bezahlt speichern',
    markAllAsPaid: 'Alles als Bezahlt speichern',
    articleGroupDetail: '{total} CHF ({count} x {price} CHF)',
    save: 'Speichern'
  },
  labels: {
    close: 'Schliessen',
    states: {
      all: 'Alle',
      new: 'Neu',
      open: 'Offen',
      kitchen: 'Küche',
      delivered: 'Ausgeliefert',
      closed: 'Geschlossen'
    },
    icons: {
      all: 'list',
      new: 'fiber_new',
      open: 'view_module',
      kitchen: 'kitchen',
      delivered: 'transform',
      closed: 'archive'
    }
  },
  navigation: {
    title: 'Navigation',
    adminTitle: 'Administration',
    deliverTitle: 'Ausliefern',
    nav: {
      homepage: 'Startseite',
      tables: 'Tischauswahl',
      orders: 'Bestellungen',
      kitchen: 'Küche',
      buffet: 'Büffet',
      coffee: 'Kaffee',
      help: 'Hilfe',
      about: 'Über die App',
      logout: 'Ausloggen'
    },
    adminNav: {
      tables: 'Tische verwalten',
      articles: 'Artikel verwalten',
      articleCategories: 'Artikel Categorien verwalten',
      users: 'Benützer verwalten',
      reports: 'Reporting',
      ticker: 'Server status'
    }
  },
  login: {
    title: 'Einloggen',
    username: 'Benutzername',
    password: 'Passwort',
    login: 'Einloggen'
  },
  kitchen: {
    deliver: 'Ausliefern',
    deliverConfirmation: 'Bestätigen'
  }
}
