<template>
  <Layout>
    <div class="orders">
      <div class="cnt">
        <div style="float: right; width: 150px; margin-top: 0px">
          <v-switch :label="$t('orders.allTables')" v-model="showAllTables"></v-switch>
        </div>
        <h1>{{ $t("orders.title") }}</h1>

        <div v-if="loading" class="text-xs-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
        <v-list v-else two-line class="orderList">
          <template v-for="(order, i) in orders">
            <v-subheader v-if="i == 0 || orders[i - 1].state != order.state" :key="'title' + order.id" class="title">
              <v-avatar>
                <v-icon>{{ $t("labels.icons." + order.state) }}</v-icon>
              </v-avatar>
              {{ $t("labels.states." + order.state) }}
            </v-subheader>
            <v-list-item ripple :key="'list' + order.id" @click="showOrder(order)">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ order.table.name }}</strong>
                  <small v-if="order.name">({{ order.name }})</small>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t("orders.orderSummary", { paid: order.sumpaid, total: order.sum }) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{ $t("labels.states." + order.state) }}</v-list-item-action-text>
                <v-icon color="green lighten-1" v-if="order.sumpaid == order.sum">done_all</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="i + 1 < orders.length" :key="i"></v-divider>
          </template>

          <v-speed-dial
            v-model="fab"
            bottom
            right
            :direction="orders.length > 3 ? 'top' : 'bottom'"
            :transition="'slide-y-reverse-transition'"
            class="flyOutAction"
          >
            <v-btn slot="activator" color="blue darken-2" dark fab hover v-model="fab" class="flyOutActionButton">
              <v-icon v-if="fab">close</v-icon>
              <v-icon v-else>add_circle</v-icon>
            </v-btn>
            <v-list :class="'flyOutActionList w-'+Math.floor(tables.length / 5)">
              <div>
                <v-container fluid>
                <v-row>
                  <v-col v-for="item in tables" :key="item.id" :cols="12 / (Math.floor(tables.length / 5))">
                    <v-btn color="green" @click="createTable(item.id)">{{ item.name }}</v-btn>
                  </v-col>
                </v-row>
              </v-container>
              </div>
            </v-list>
          </v-speed-dial>
        </v-list>
      </div>

      <v-bottom-navigation v-model="listState" absolute color="accent" shift>
        <v-btn :value="state" v-for="(state, i) in states" :key="i" class="pa-0">
          <span>{{ $t("labels.states." + state) }}</span>
          <v-icon>{{ $t("labels.icons." + state) }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import EventBus from "../utils/event-bus.js";

export default {
  name: "orders",
  data() {
    return {
      loading: true,
      tables: [],
      orders: [],
      showAllTables: false,
      fab: null,
      listState: "open",
      states: ["open", "new", "kitchen", "delivered", "closed"],
    };
  },
  components: {
    Layout,
  },
  watch: {
    listState: function (val) {
      this.loadOrders(val);
    },
    showAllTables: function () {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      this.loadTables(this.showAllTables);
      this.loadOrders(this.listState);
    },
    loadTables: function (showAllTables) {
      let url = `/api/tables`;
      if (!showAllTables) {
        url = `/api/tables?filter=byUserTable`;
      }
      axios
        .get(url)
        .then((response) => {
          this.tables = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    loadOrders: function (type) {
      axios
        .get(`/api/orders?state=${type}${this.showAllTables ? "&tables=all" : ""}`)
        .then((response) => {
          this.orders = response.data;
          this.loading = false;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    createTable: function (tableId) {
      axios
        .post(`/api/orders`, {
          tableId: tableId,
        })
        .then((order) => {
          this.$router.push(`/orders/${order.data.id}`);
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
    showOrder: function (order) {
      this.$router.push(`/orders/${order.id}`);
    },
  },
};
</script>

<style scope lang="scss">
.orders {
  .flyOutAction {
    z-index: 5;
    float: right;

    .flyOutActionButton {
      margin-top: 20px;
    }
    .flyOutActionList {
      border: 3px solid silver;
      background-color: #efefef;
      border-radius: 10px;
      margin-right: 10rem;
      width: 150px;

      &.w-2 {
        width: 250px;
      }
      &.w-3 {
        width: 350px;
      }
      &.w-4 {
        width: 450px;
      }
    }
  }

  .orderList {
    margin: 10px 0 100px 0;
    position: relative;

    .title {
      size: 116px;
      color: orange;
    }
  }
}
</style>
