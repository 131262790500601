<template>
  <Layout>
    <div class="articles cnt">
      <h1>Manage articles</h1>

      <ul v-if="errors && errors.length">
        <li v-for="error of errors" :key="error.message">
          {{ error.message }}
        </li>
      </ul>

      <v-data-table :headers="headers" :items="articles" class="elevation-1" :options="tableOptions">
        <template v-slot:item="{ item }">
          <tr :style="(item.state === 'inactive')? 'text-decoration: line-through;': ''">
            <td :style="'border-left: 5px solid '+item.articlecategory.color">{{ item.name }}</td>
            <td>
              <v-icon :color="item.articlecategory.color">{{ item.articlecategory.icon }}</v-icon>
            </td>
            <td>{{ item.target }}</td>
            <td>{{ item.price }}</td>
            <td>{{ item.state }}</td>
            <td>
              <v-btn v-if="item.state === 'inactive'" icon class="mx-0" v-on:click="setState(item.id, 'activate')">
                <v-icon color="silver">done_outline</v-icon>
              </v-btn>
              <v-btn v-else icon class="mx-0" v-on:click="setState(item.id, 'deactivate')">
                <v-icon color="silver">block</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" v-on:click="editItem(item)">
                <v-icon color="teal">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="deleteItem(item)">
                <v-icon color="pink">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-btn color="primary" dark class="mt-5" @click="editItem(null)">Hinzufügen</v-btn>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="Name" v-model="article.name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select
                    :items="categories"
                    v-model="article.articlecategoryId"
                    label="categories"
                    single-line
                    item-text="name"
                    item-value="id"
                    return-object
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select :items="types" v-model="article.target" label="Target" single-line></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="Preis" v-model="article.price"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click.native="close">Cancel</v-btn>
            <v-btn color="blue darken-1" @click.native="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";
import EventBus from "../../utils/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Category", align: "left", value: "cat" },
        { text: "Target", align: "left", value: "target" },
        { text: "Price", value: "price" },
        { text: "State", value: "state" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      articles: [],
      errors: [],
      editedIndex: -1,
      article: {},
      categories: [],
      types: ["kitchen", "buffet", "coffee"],
      tableOptions: {
        itemsPerPage: 100,
      },
    };
  },

  components: {
    Layout,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      axios
        .get(`/api/articlecategories`)
        .then((response) => {
          this.categories = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });

      axios
        .get(`/api/articles?state=all`)
        .then((response) => {
          this.articles = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    editItem: function (item) {
      this.article = {};
      this.editedIndex = this.articles.indexOf(item);
      if (this.editedIndex >= 0) {
        this.article = Object.assign({}, item);
      }
      this.dialog = true;
    },

    setState: function (id, action) {
      axios
        .get(`/api/articles/${id}/${action}`)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    deleteItem: function (item) {
      if (confirm("Are you sure you want to delete this item?")) {
        axios
          .delete(`/api/articles/${item.id}`)
          .then(() => {
            this.initialize();
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });
      }
    },

    close: function () {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save: function () {
      this.article.articlecategoryId = this.article.articlecategoryId.id;
      delete this.article.cat;
      axios
        .post(`/api/articles/${this.article.id || ""}`, this.article)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });

      this.close();
    },
  },
};
</script>
