<template>
  <Layout>
    <div class="tables cnt">
      <h1>Tische wählen</h1>

      <v-alert :value="selected.length == 0" type="info">
        Du hast noch keine Tische ausgewählt. Bitte wähl die Tische aus für welche du eingeteilt bist und speicher die auswahlt bevor du zu den Bestellungen
        gehst.
      </v-alert>

      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs4 v-for="table in tables" :key="table.id">
            <v-switch :label="table.name" v-model="selected" :value="table.id"></v-switch>
          </v-flex>
        </v-layout>
      </v-container>
      <v-btn color="success" v-on:click="saveSelection()">Auswahl speichern</v-btn>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import EventBus from "../utils/event-bus.js";

export default {
  data() {
    return {
      tables: [],
      selected: [],
    };
  },
  components: {
    Layout,
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      axios
        .get(`/api/tables`)
        .then((response) => {
          this.tables = response.data;
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });

      axios
        .get(`/api/tableassignments`)
        .then((response) => {
          this.selected = [];
          response.data.forEach((rel) => {
            this.selected.push(rel.tableId);
          });
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
    },
    saveSelection: function () {
      axios
        .post(`/api/tableassignments`, {
          tableIds: this.selected,
        })
        .then(() => {
          EventBus.$emit("showMessage", `Table selection saved`);
          if (this.selected.length) {
            this.$router.push("/orders");
          }
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
    },
  },
};
</script>
