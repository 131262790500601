import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', name: 'home', component: require('@/views/Home').default },
    { path: '/tables', name: 'Tables', component: require('@/views/Tables').default },
    { path: '/orders', name: 'Oders', component: require('@/views/Orders').default },
    { path: '/orders/:id', name: 'Oder details', component: require('@/views/OrderDetails').default },
    { path: '/kitchen', name: 'Kitchen', component: require('@/views/Kitchen').default },
    { path: '/buffet', name: 'Buffet', component: require('@/views/Buffet').default },
    { path: '/coffee', name: 'Kaffee', component: require('@/views/Coffee').default },
    { path: '/help', name: 'Help', component: require('@/views/Help').default },
    { path: '/about', name: 'About', component: require('@/views/About').default },
    { path: '/login', name: 'Login', component: require('@/views/Login').default },
    { path: '/logout', name: 'Logout', component: require('@/views/Logout').default },
    { path: '/admin/tables', name: 'AdminTables', component: require('@/views/admin/Tables').default },
    { path: '/admin/articles', name: 'AdminArticles', component: require('@/views/admin/Articles').default },
    { path: '/admin/articlecategories', name: 'AdminArticleCategories', component: require('@/views/admin/ArticleCategories').default },
    { path: '/admin/users', name: 'AdminUsers', component: require('@/views/admin/Users').default },
    { path: '/admin/reports', name: 'AdminReports', component: require('@/views/admin/Reports').default },
    { path: '/admin/ticker', name: 'Ticker', component: require('@/views/admin/Ticker').default },

    { path: '*', redirect: '/' }
  ]
})

export default router