<template>
  <Layout>
    <div class="users cnt">
      <h1>Manage users</h1>

      <ul v-if="errors && errors.length">
        <li v-for="error of errors" :key="error.message">
          {{ error.message }}
        </li>
      </ul>

      <v-data-table :headers="headers" :items="users" class="elevation-1">
        <template v-slot:item="{ item }">
          <tr :style="(item.state === 'inactive')? 'text-decoration: line-through;': ''">
            <td>{{ item.username }}</td>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.permissions }}</td>
            <td>{{ item.state }}</td>
            <td>{{ item.lastLoginDate }}</td>
            <td>
              <v-btn v-if="item.state === 'inactive'" icon class="mx-0" v-on:click="setState(item.id, 'activate')">
                <v-icon color="teal">done_outline</v-icon>
              </v-btn>
              <v-btn v-else icon class="mx-0" v-on:click="setState(item.id, 'deactivate')">
                <v-icon color="teal">block</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" v-on:click="editItem(item)">
                <v-icon color="teal">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="deleteItem(item)">
                <v-icon color="pink">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-btn color="primary" dark class="mt-5" @click="editItem(null)">Hinzufügen</v-btn>

      <v-dialog v-model="dialog" max-width="500px" eager>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="Username" ref="Username" v-model="user.username" :rules="[rules.required]" required></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="FirstName" ref="FirstName" v-model="user.firstName"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field label="LastName" ref="LastName" v-model="user.lastName"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select
                    :items="permissions"
                    v-model="user.permissions"
                    label="Permission"
                    ref="Permissions"
                    single-line
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-select :items="states" v-model="user.state" label="Status" ref="status" single-line></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field label="Password" ref="Password" v-model="user.password" :rules="[rules.required]" required></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click.native="close">Cancel</v-btn>
            <v-btn color="blue darken-1" @click.native="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";
import EventBus from "../../utils/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      states: ["active", "inactive"],
      permissions: ["read", "use", "deliver", "admin"],
      headers: [
        { text: "username", align: "left", value: "username" },
        { text: "firstName", align: "left", value: "firstName" },
        { text: "lastName", align: "left", value: "lastName" },
        { text: "permissions", align: "left", value: "permissions" },
        { text: "state", align: "left", value: "state" },
        { text: "lastLoginDate", align: "left", value: "lastLoginDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      users: [],
      errors: [],
      editedIndex: -1,
      user: {},
      fieldsToValidate: ["Username", "FirstName", "LastName", "Permissions", "Password"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  components: {
    Layout,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      axios
        .get(`/api/users?state=all`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    setState: function (id, action) {
      axios
        .get(`/api/users/${id}/${action}`)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    resetForm: function () {
      this.fieldsToValidate.forEach((f) => {
        this.$refs[f].reset();
      });
      this.user = {};
    },

    editItem: function (item) {
      var u = null;
      this.editedIndex = -1;
      this.user = {};
      if (!item) {
        this.resetForm();
      } else {
        u = this.users.find((user) => {
          return user.username === item.username;
        });
        this.editedIndex = this.users.indexOf(u);
        if (this.editedIndex >= 0) {
          this.user = Object.assign({}, u);
        }
      }
      this.dialog = true;
    },

    deleteItem: function (item) {
      if (confirm("Are you sure you want to delete this item?")) {
        axios
          .delete(`/api/users/${item.id}`)
          .then(() => {
            this.initialize();
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });
      }
    },

    close: function () {
      this.dialog = false;
      setTimeout(() => {
        this.editedIndex = -1;
      }, 300);
    },

    save: function () {
      let validated = true;
      this.fieldsToValidate.forEach((f) => {
        if (!this.$refs[f].validate(true)) {
          validated = false;
        }
      });

      if (validated) {
        axios
          .post(`/api/users/${this.user.id || ""}`, this.user)
          .then(() => {
            this.initialize();
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });

        this.close();
      }
    },
  },
};
</script>
