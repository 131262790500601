import moment from 'moment'

function formatDuration(duration) {
  var ret = ''
  if (duration.seconds() > 0) {
    ret = `${duration.seconds()}s`
  }
  if (duration.minutes() > 0) {
    ret = `${duration.minutes()}m ${ret}`
  }
  if (duration.hours() > 0) {
    ret = `${duration.hours()}h ${ret}`
  }
  if (duration.days() > 0) {
    ret = `${duration.days()}d ${ret}`
  }
  return ret
}

export const dt = {
  filters: {
    toISOString(str) {
      return moment(str).toISOString()
    },

    formatDate(str, format = null, outputFormat = 'YYYY-MM-DD HH:mm:ss', hideDateIfSame = false) {
      var duration = moment.duration(moment().diff(moment(str)))

      if (hideDateIfSame && duration.days() <= 0) {
        outputFormat = 'HH:mm:ss'
      }

      if (format == null) {
        return moment(str).format(outputFormat)
      }

      return moment(str, format).format(outputFormat)
    },

    diffForHumans(str) {
      return moment(str).from(moment())
    },

    getTimeDiffToNow(str) {
      var duration = moment.duration(moment().diff(moment(str)))
      return formatDuration(duration)
    },

    getTimeDiff(str, rel) {
      var duration = moment.duration(moment(str).diff(moment(rel)))
      return formatDuration(duration)
    }
  },

  methods: {

  }
}
