<template>
  <Layout>
    <div class="cnt">
      <h1>System status</h1>

      <v-alert class="my-5"
               elevation="5"
               icon="info"
               outlined
               text
               :type="serverState">{{ serverMsg }}

      <pre>{{ stats }}</pre>
    </v-alert>

    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";

export default {
  data() {
    return {
      serverState: "success",
      stats: {}
    };
  },

  components: {
    Layout,
  },

  watch: {

  },

  computed: {

  },

  created() {
    this.getData();
    this.timer = setInterval(this.getData, 10000);
  },

  methods: {
    getData: function () {
      axios
        .get(`/api/reports/overall`)
        .then((response) => {
          this.serverMsg = `Ok, server answered at ${new Date()}`;
          this.serverState = "success";
          this.stats = response.data;
        })
        .catch(err => {
          this.serverMsg = err;
          this.serverState = "error";
          this.stats = "";
        });
    },
  },
};
</script>
