<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-title class="title">{{ $t("navigation.title") }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense class="pt-0">
      <v-list-item v-for="subItem in navs" :key="subItem.title">
        <v-list-item-action>
          <v-icon :color="subItem.color || ''">{{ subItem.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <router-link :to="subItem.path" class="nav-link" active-class="active">
              {{ subItem.title }}
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="isDeliver">
      <v-divider></v-divider>
      <v-toolbar flat>
        <v-list>
          <v-list-item>
            <v-list-item-title class="title">{{ $t("navigation.deliverTitle") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-item v-for="subItem in deliverNav" :key="subItem.title">
          <v-list-item-action>
            <v-icon :color="subItem.color || ''">{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <router-link :to="subItem.path" class="nav-link" active-class="active">
                {{ subItem.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="isAdmin">
      <v-divider></v-divider>
      <v-toolbar flat>
        <v-list>
          <v-list-item>
            <v-list-item-title class="title">{{ $t("navigation.adminTitle") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-item v-for="subItem in adminNavs" :key="subItem.title">
          <v-list-item-action>
            <v-icon :color="subItem.color || ''">{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <router-link :to="subItem.path" class="nav-link" active-class="active">
                {{ subItem.title }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <br /><br /><br />
  </div>
</template>

<script>
import authMixin from "../utils/auth";
import EventBus from "../utils/event-bus.js";

export default {
  data() {
    return {
      navs: [
        { title: this.$t("navigation.nav.homepage"), icon: "home", path: "/", color: "green" },
        { title: this.$t("navigation.nav.tables"), icon: "near_me", path: "/tables", color: "blue" },
        { title: this.$t("navigation.nav.orders"), icon: "local_grocery_store", path: "/orders", color:"purple" },
        { title: this.$t("navigation.nav.help"), icon: "help", path: "/help", color: "orange" },
        { title: this.$t("navigation.nav.about"), icon: "question_answer", path: "/about" },
        { title: this.$t("navigation.nav.logout"), icon: "logout", path: "/logout", color: "red" },
      ],
      deliverNav: [
      { title: this.$t("navigation.nav.kitchen"), icon: "kitchen", path: "/kitchen", color: "purple" },
        { title: this.$t("navigation.nav.buffet"), icon: "local_bar", path: "/buffet", color: "purple" },
        { title: this.$t("navigation.nav.coffee"), icon: "coffee", path: "/coffee", color: "purple" },

      ],
      adminNavs: [
        { title: this.$t("navigation.adminNav.tables"), icon: "tablet", path: "/admin/tables", color: "orange" },
        {
          title: this.$t("navigation.adminNav.articles"),
          icon: "shopping_basket",
          path: "/admin/articles", color: "orange"
        },
        {
          title: this.$t("navigation.adminNav.articleCategories"),
          icon: "more",
          path: "/admin/articlecategories", color: "orange"
        },
        { title: this.$t("navigation.adminNav.users"), icon: "verified_user", path: "/admin/users", color: "orange" },
        { title: this.$t("navigation.adminNav.reports"), icon: "timeline", path: "/admin/reports", color: "orange" },
        { title: this.$t("navigation.adminNav.ticker"), icon: "info", path: "/admin/ticker", color: "orange" }
      ],
      right: null,
      isDeliver: false,
      isAdmin: false,
    };
  },
  mixins: [authMixin],
  created() {
    this.initialize();

    EventBus.$on("setUser", () => {
      this.initialize();
    });
  },
  methods: {
    initialize: function () {
      this.checkIfLogged().then((user) => {
        if (user) {
          switch(user.permissions) {
            case "admin":
              this.isAdmin = true;
              this.isDeliver = true;
              break;

            case "deliver":
              this.isDeliver = true;
              break;
          }
        }
      });
    },
  },
};
</script>
