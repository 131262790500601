<template>
  <Layout>
    <div class="help cnt">
      <h1>Hilfe, <small>Wie Funktionierts?</small></h1>
      <h3>Startseite</h3>
      <p>Wenn du auf der Startseite landest und schon eingeloggt bist, kannst du direkt zu den Bestellungen. Wenn nicht sollte das Login Fenstger kommen.</p>
      <h3>Tischauswahl</h3>
      <p>Hast du keine Tische ausgewählt, wirst du automatisch auf diese Seite weiter geleitet nach dem Login.</p>
      <p>Wähle die Tische aus für welche du verantwortlich bist und speicher die auswahl. Danach kannst du via Menu zu den Bestellungen</p>
      <h3>Bestellungen</h3>
      <p>
        Auf der Seite der Bestellungen bekommst du eine Liste mit Bestellungen die zu deinen Tischen gehören. Unten kannst du die Liste nach Status filtern. Per
        default wird dir immer angezeigt was noch nicht abeschlossen wurde.
      </p>
      <p>Wenn du eine Bestellungen in der Liste anklickst gelangst du auf die Detail Seite einer Bestellung.</p>
      <p>Mit dem Plus Knopf unten rechts kannst du eine neue Bestellung für einen Tisch eröfnen.</p>
      <h3>Bestellungesdetails</h3>
      <p>
        Als erstes hast du ganz oben rechts der aktuelle Status in welcher sich die Bestellung befindet. Willst du den Status ändern, kannst du dort drauf
        clicken und einen neuen Status auswählen
      </p>
      <p>Unter dem Title kannst du oben eine Beschreibung/Notiz hinterlegen.</p>
      <p>Unten rechts kannst du Artikel der Bestellung hinzufügen. Click ein Artikel so oft an wie bestellt werden soll.</p>
      <p>Mit dem Pfeil nach links unten rechts gelangst du wieder zur Bestellungsliste</p>
      <p>
        Du kannst eine Bestellung auch speichern. Das passiert aber auch automatischen wenn den Status änderst oder die Seite verlässt. Ladest du die Seite neu
        sind deine änderungen dafür verloren.
      </p>
      <p>
        Clickst du in der Liste auf die Anzahl vor einem Artikel, wird diese in den Zwischenspeicher geladen. Du kannst mehere Artikel so oft wie bestellt
        anclicken. Ist dieser Teil Beszahlt worden, kannst du mit den Knopf "Bezahlt und Abziehen" diese Artikel als Bezahlt markieren. Sie tauchen nun nicht
        mehr in der Liste "Offen" oben auf, sondern in der Liste unten "Bezahlt". Sind alle Atikel bezahlt worden wird die Bestellung grün in der
        Übersichtsliste angezeigt.
      </p>
      <h3>Büffet</h3>
      <p>Übersicht aller Bestellungen welche im Status Küche sind und noch nicht ausgelifert wurden. Verwende den user _Büffet um dich an zu melden.</p>
      <p>
        Du kannst rechts eine Bestellung als Ausgelifert markieren. Somit verschwindet sie aus dieser Liste. Hat die Bestellung keine Artikel für die Küche, wir
        die Bestellung direkt als "Ausgelifert" markiert. Hat sie aber Artikel für die Küche wird die Bestellung erst in den Status Ausgelifert gesetzt wenn
        auch die Küche die Bestellung ausgeliefert hat.
      </p>
      <p>Wird ein Artikel einer Bestellung hinzugefügt welche sich schon in dem Status Küche befindet, wird dies direkt in der Kücheansicht angepasst.</p>
      <p>
        Wird ein Artikel einer Bestellung hinzugefügt welche schon ausgeliefrt wurde, muss diese wieder in die Küche geschickt werden via Knopf unten rechts in
        der Detail Ansicht der Bestellung. In der Küchen Übersicht wird nur angezeigt was neu dazu gekommen ist.
      </p>
      <p>
        Es kann sein das eine Bestellung nur Aritkel für die Küche hat. In diesem Fall wird der Eintrag leer angezgit. Dieser kann sofort als Ausgeliefert
        markiert werden.
      </p>
      <h3>Küche</h3>
      <p>Identisch zur Buffet Übersicht, aber halt für die Küche. Die Regeln sind die gleichen einfach vis versa zum Büffet</p>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
export default {
  data() {
    return {};
  },
  components: {
    Layout,
  },
};
</script>
