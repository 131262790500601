<template>
  <div>
    <div style="float: right; width: 300px; margin-top: 0px">
      <v-switch label="Header immer ganz anzeigen"
                v-model="showAllHeaders"></v-switch>
    </div>
    <div style="float: right; width: 180px; margin-top: 0px">
      <v-switch label="Ton abspielen"
                v-model="playSound"></v-switch>
    </div>
    <hr style="clear: both" />
    <v-simple-table v-if="orders.length || showAllHeaders"
                    class="deliverylist">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers"
                :key="header.text"
                v-html="header.text" />
            <th v-if="allowDelivery">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in orders"
              :key="i">
            <td v-html="item.name"
                class="pa-0"></td>
            <template v-for="(header, i) in headers.filter((h) => {
              return h.value != 'name';
            })">
              <td :key="i"
                  class="text-xs-center"
                  v-if="item[header.value]">{{ item[header.value].nb }}</td>
              <td :key="i"
                  class="text-xs-center"
                  v-else>-</td>
            </template>
            <td v-if="allowDelivery">
              <v-menu left
                      offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary"
                         dark
                         v-bind="attrs"
                         v-on="on">
                    {{ $t("kitchen.deliver") }}
                  </v-btn>
                </template>
                <v-btn color="success"
                       @click="deliverOrder(item)">{{ $t("kitchen.deliverConfirmation") }}</v-btn>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p v-else>No order to process</p>
    <v-progress-circular indeterminate
                         color="primary"
                         v-if="loading"></v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from "../utils/event-bus.js";

export default {
  data() {
    return {
      timer: "",
      orders: [],
      headers: [{ text: "Name", align: "center", value: "name", sortable: false }],
      tempOrder: {},
      loading: false,
      allowDelivery: false,
      showAllHeaders: false,
      allHeaders: [],
      playSound: false,
      lastAddedDate: null,
    };
  },

  props: {
    target: {
      type: String,
      default: () => "kitchen",
    },
  },

  watch: {
    showAllHeaders: function (val) {
      if (val) {
        if (this.allHeaders.length === 0) {
          axios.get(`/api/articles?target=${this.target}&sort=name`).then((articles) => {
            let heads = [];

            articles.data.forEach((art) => {
              heads.push({
                text: art.name.replace(/\s/g, "<br />"),
                align: "center",
                value: art.id,
                sortable: false,
              });
            });

            this.allHeaders = heads;
            this.loadList();
          });
        }
      }
      else {
        this.loadList();
      }
    },
  },

  created() {
    this.loadList();
    this.timer = setInterval(this.loadList, 10000);
    if (localStorage.user) {
      let u = JSON.parse(localStorage.user);
      this.allowDelivery = u.permissions === "deliver" || u.permissions === "admin";
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    loadList: function () {
      this.loading = true;
      axios
        .get(`/api/orders?tables=all&state=kitchen&details=all&target=${this.target}&sort=oldest`)
        .then((response) => {
          this.processOrders(response.data);
          this.loading = false;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", "Error: " + e, 10000);
          console.log(e);
          this.loading = false;
        });
    },

    processOrders: function (orders) {
      let headers = [];

      if (this.showAllHeaders) {
        headers = this.allHeaders;
      } else {
        orders.forEach((order) => {
          order.orderdetails.forEach((artGroup) => {
            let nm = artGroup.article.name.replace(/\s/g, "<br />");
            let h = headers.filter((t) => {
              return t.text === nm;
            });
            if (h.length === 0 && artGroup.count - artGroup.delivered > 0) {
              headers.push({
                text: nm,
                align: "center",
                value: artGroup.article.id,
                sortable: false,
              });
            }
          });
        });
      }

      headers.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
      this.headers = [
        {
          text: "Name",
          align: "center",
          value: "name",
          sortable: false,
        },
      ].concat(headers);

      this.orders = [];
      orders.forEach((order) => {
        let d = {
          id: order.id,
          kitchenat: order.kitchenat,
          name: `<strong>${order.table.name}</strong> ${order.user.firstName} ${order.user.lastName}`,
        };
        d.name += " (" + new Date(order.kitchenat).toLocaleTimeString() + ")";
        if (order.name) {
          d.name += "<br />" + order.name;
        }

        let ct = false;
        order.orderdetails.forEach((artGroup) => {
          if (artGroup.count - artGroup.delivered > 0) {
            d[artGroup.article.id] = {
              nb: artGroup.count - artGroup.delivered,
              id: artGroup.id,
            };
            ct = true;
          }
        });

        if (ct) {
          this.orders.push(d);
        }
      });

      if (this.playSound && this.orders && this.orders.length > 0) {
        if (this.lastAddedDate !== this.orders[this.orders.length - 1].kitchenat) {
          var audio = new Audio(require("../assets/dong.mp3"));
          audio.play();
          this.lastAddedDate = this.orders[this.orders.length - 1].kitchenat;
        }
      } else {
        this.lastAddedDate = null;
      }
    },

    deliverOrder: function (order) {
      this.tempOrder = order;
      axios
        .post(`/api/orderdetails`, order)
        .then(() => {
          axios
            .post(`/api/orders/${order.id}/setstate`, { state: "delivered" })
            .then(() => {
              this.loadList();
            })
            .catch((e) => {
              EventBus.$emit("showMessage", "Error: " + e, 10000);
              console.log(e);
            });
        })
        .catch((e) => {
          EventBus.$emit("showMessage", "Error: " + e, 10000);
          console.log(e);
        });
    },
  },
};
</script>

<style scope lang="scss">
.deliverylist {
  th {
    padding: 2px !important;
    max-width: 100px;
    border-right: 1px dashed silver;
  }

  td {
    border-right: 1px dashed silver;
  }
}
</style>
