<template>
  <Layout>
    <div class="articlecategories cnt">
      <h1>Manage articlecategories</h1>

      <ul v-if="errors && errors.length">
        <li v-for="error of errors" :key="error.message">
          {{ error.message }}
        </li>
      </ul>

      <v-data-table :headers="headers" :items="articlecategories" class="elevation-1" :options="tableOptions">
        <template v-slot:item="{ item }">
          <tr>
            <td :style="'border-left: 5px solid ' + item.color">{{ item.name }}</td>
            <td>
              <v-icon :color="item.color">{{ item.icon }}</v-icon> {{ item.icon }}
            </td>
            <td>
              <div :style="'padding: 5px; background-color: ' + item.color">{{ item.color }}</div>
            </td>
            <td>
              <div>{{ item.sorting }}</div>
            </td>

            <td>
              <v-btn icon class="mx-0" v-on:click="editItem(item)"><v-icon color="teal">edit</v-icon></v-btn>
              <v-btn icon class="mx-0" @click="deleteItem(item)"><v-icon color="pink">delete</v-icon></v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-btn color="primary" dark class="mb-2" @click="editItem(null)">Hinzufügen</v-btn>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <v-text-field label="Name" v-model="table.name"></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field label="Icon" v-model="table.icon"></v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field v-model="table.color" v-mask="mask" hide-details class="ma-0 pa-0" solo>
                    <template v-slot:append>
                      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="table.color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex>
                  <v-text-field label="Sorting" v-model="table.sorting"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click.native="close">Cancel</v-btn>
            <v-btn color="blue darken-1" @click.native="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";
import EventBus from "../../utils/event-bus.js";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      dialog: false,
      menu: false,
      mask: "!#XXXXXXXX",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Icon", align: "left", value: "icon" },
        { text: "Color", align: "left", value: "color" },
        { text: "Sort", align: "left", value: "sorting" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      articlecategories: [],
      errors: [],
      editedIndex: -1,
      table: {},
      tableOptions: {
        itemsPerPage: 100,
      },
    };
  },
  directives: { mask },

  components: {
    Layout,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    swatchStyle() {
      const { menu } = this;
      const { color } = this.table;

      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        border: "1px solid black",
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      axios
        .get(`/api/articlecategories`)
        .then((response) => {
          this.articlecategories = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    editItem: function (item) {
      this.table = {};
      this.editedIndex = this.articlecategories.indexOf(item);
      if (this.editedIndex >= 0) {
        this.table = Object.assign({}, item);
      }
      this.dialog = true;
    },

    deleteItem: function (item) {
      if (confirm("Are you sure you want to delete this item?")) {
        axios
          .delete(`/api/articlecategories/${item.id}`)
          .then(() => {
            this.initialize();
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });
      }
    },

    close: function () {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save: function () {
      axios
        .post(`/api/articlecategories/${this.table.id || ""}`, this.table)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });

      this.close();
    },
  },
};
</script>
