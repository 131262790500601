<template>
  <Layout>
    <div class="tables cnt">
      <h1>Manage tables</h1>

      <ul v-if="errors && errors.length">
        <li v-for="error of errors" :key="error.message">
          {{ error.message }}
        </li>
      </ul>

      <v-data-table :headers="headers" :items="tables" class="mt-5 elevation-1">
        <template v-slot:item="{ item }">
          <tr :style="(item.state === 'inactive')? 'text-decoration: line-through;': ''">
            <td>{{ item.name }}</td>
            <td>
              <v-btn v-if="item.state === 'inactive'" icon class="mx-0" v-on:click="setState(item.id, 'activate')">
                <v-icon color="teal">done_outline</v-icon>
              </v-btn>
              <v-btn v-else icon class="mx-0" v-on:click="setState(item.id, 'deactivate')">
                <v-icon color="silver">block</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" v-on:click="editItem(item)">
                <v-icon color="teal">edit</v-icon>
              </v-btn>
              <v-btn icon class="mx-0" @click="deleteItem(item)">
                <v-icon color="pink">delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-btn color="primary" dark class="mt-5" @click="editItem(null)">Hinzufügen</v-btn>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <v-text-field label="Name" v-model="table.name"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click.native="close">Cancel</v-btn>
            <v-btn color="blue darken-1" @click.native="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";
import EventBus from "../../utils/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tables: [],
      errors: [],
      editedIndex: -1,
      table: {},
    };
  },

  components: {
    Layout,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      axios
        .get(`/api/tables?state=all`)
        .then((response) => {
          this.tables = response.data;
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    editItem: function (item) {
      this.table = {};
      this.editedIndex = this.tables.indexOf(item);
      if (this.editedIndex >= 0) {
        this.table = Object.assign({}, item);
      }
      this.dialog = true;
    },

    setState: function (id, action) {
      axios
        .get(`/api/tables/${id}/${action}`)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    deleteItem: function (item) {
      if (confirm("Are you sure you want to delete this item?")) {
        axios
          .delete(`/api/tables/${item.id}`)
          .then(() => {
            this.initialize();
          })
          .catch((e) => {
            EventBus.$emit("showMessage", e);
          });
      }
    },

    close: function () {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save: function () {
      axios
        .post(`/api/tables/${this.table.id || ""}`, this.table)
        .then(() => {
          this.initialize();
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });

      this.close();
    },
  },
};
</script>
