<template>
  <v-app id="inspire">
    <v-navigation-drawer temporary v-model="drawer" app>
      <Navigation />
    </v-navigation-drawer>
    <AppBar :menuClick="menuClick" />
    <v-main>
      <slot />
    </v-main>
    <v-snackbar :timeout="snackTimeout" top left multi-line v-model="snackBar">
      {{ snackText }}
      <v-btn text color="pink" @click.native="snackBar = false">{{ $t("labels.close") }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "../components/AppBar";
import Navigation from "../components/Navigation.vue";
import EventBus from "../utils/event-bus.js";
import { mdiLogin, mdiLogout, mdiThemeLightDark } from "@mdi/js";
//import SnackBar from "@/components/_system/snackbar.vue";

export default {
  name: "Home",
  components: {
    AppBar,
    Navigation,
  },
  data: () => ({
    icons: { mdiLogin, mdiLogout, mdiThemeLightDark },
    drawer: false,
    snackBar: false,
    snackText: "Message to display",
    snackTimeout: 5000,
    username: null,
  }),
  mounted() {
    EventBus.$on("showMessage", (msg, timeout) => {
      this.snackText = msg;
      this.snackTimeout = timeout || 5000;
      this.snackBar = true;
    });

    EventBus.$on("setUser", () => {
      this.username = "";
      if (localStorage.user) {
        let u = JSON.parse(localStorage.user);
        this.username = `${u.firstName} ${u.lastName}`;
      }
    });
  },
  methods: {
    menuClick() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss">
.Home {
  .v-app-bar {
    h2 {
      text-transform: uppercase;
    }
  }
}
</style>
