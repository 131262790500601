import axios from 'axios'

var authMixin = {
  user: null,
  methods: {
    checkIfLogged() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/session')
          .then(response => {
            this.user = response.data.user
            resolve(response.data.user)
          })
          .catch(error => {
            this.user = null
            reject(error.response.data)
          })
      })
    },
    doLogin(data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/login', data)
          .then(response => {
            if (response && response.data && response.data.id) {
              this.user = response.data
              resolve(response.data)
            } else {
              this.user = null
              reject(new Error('Wrong password'))
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default authMixin
