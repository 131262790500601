<template>
  <Layout>
    <v-container>
      <div class="tables cnt">
        <h1>Auf wiedersehen</h1>
        <p>Sie sind jetzt ausgeloggt und können die Seite schliessen oder sich neu anmelden.</p>
      </div>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import EventBus from "../utils/event-bus.js";

export default {
  name: "LogoutPage",
  components: {
    Layout,
  },
  data() {
    return {};
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize: function () {
      axios
        .get(`/api/logout`)
        .then(() => {
          EventBus.$emit("showMessage", "Ausgeloggt");

          localStorage.clear();
          EventBus.$emit("setUser");

          var that = this;
          setTimeout(function () {
            that.$router.push("/login");
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
    },
  },
};
</script>
