<template>
  <Layout>
    <div class="about cnt">
      <h1>Über diese App <small>v2.0.0</small></h1>
      <p>Bei Fragen, einfach melden.</p>

      <p>Technologien:</p>

      <ul>
        <li>VueJs</li>
        <li>Webpack</li>
        <li>Node</li>
        <li>MariaDB</li>
        <li>ExpressJs</li>
      </ul>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
export default {
  name: "about",
  components: {
    Layout,
  },
  data() {
    return {};
  },
};
</script>
