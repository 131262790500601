<template>
  <Layout>
    <div class="cnt">
      <h1>
        <router-link to="/kitchen">Küche</router-link> / 
        <router-link to="/buffet">Büffet</router-link> /
        Kaffee  
      </h1>
      <DeliveryList target="coffee" />
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import DeliveryList from "../components/DeliveryList.vue";

export default {
  name: "about",
  data() {
    return {};
  },
  components: {
    DeliveryList,
    Layout,
  },
};
</script>
