<template>
  <Layout>
    <div class="reports cnt">
      <h1>Reports</h1>

      <v-container fluid
                   grid-list-lg>
        <v-layout row
                  wrap>
          <v-flex xs12
                  sm12
                  md6>
            <v-datetime-picker label="Von"
                               v-model="startTime"></v-datetime-picker>
          </v-flex>
          <v-flex xs12
                  sm12
                  md6>
            <v-datetime-picker label="Bis"
                               v-model="endTime"></v-datetime-picker>
          </v-flex>
          <v-speed-dial bottom
                        direction="bottom">
            <v-btn slot="activator"
                   color="blue darken-2"
                   dark
                   fab>
              <v-icon>timeline</v-icon>
              <v-icon>close</v-icon>
            </v-btn>
            <div style="margin-left: 15rem">
              <v-btn color="success"
                     @click="setFilter('lasthour')">Nur die letzte Stunde</v-btn>
              <v-btn color="success"
                     @click="setFilter('today')">Nur heute</v-btn>
              <v-btn color="success"
                     @click="setFilter('yesterday')">Nur gestern</v-btn>
              <v-btn color="success"
                     @click="setFilter('feboreyesterday')">Nur vorgestern</v-btn>
              <v-btn color="success"
                     @click="setFilter('last2days')">Die letzten 2 tage</v-btn>
              <v-btn color="success"
                     @click="setFilter('last3days')">Die letzten 3 tage</v-btn>
            </div>
          </v-speed-dial>
        </v-layout>
      </v-container>

      <highcharts :options="essenChartOptions"></highcharts>
      <highcharts :options="trinkenChartOptions"></highcharts>
      <highcharts :options="orderStatesPieOptions"></highcharts>
      <highcharts :options="orderTimesLineOptions"></highcharts>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/default.vue";
import axios from "axios";
import EventBus from "../../utils/event-bus.js";
import { Chart } from "highcharts-vue";

export default {
  data() {
    return {
      temp: [],
      startTime: new Date(),
      endTime: new Date(),
      essenChartOptions: {},
      trinkenChartOptions: {},
      defaultBarOptions: {
        chart: {
          type: "bar",
          backgroundColor: "transparent"
        },
        title: {
          text: "Küche",
        },
        series: [
          {
            name: " ",
            data: [],
          },
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Anzahl",
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
          height: 800,
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          shadow: true,
        },
      },
      orderStatesPieOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: "transparent",
          type: "pie",
        },
        title: {
          text: "Order status",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.y}",
            },
          },
        },
        series: [
          {
            name: "States",
            colorByPoint: true,
            data: [],
          },
        ],
      },
      orderTimesLineOptions: {
        chart: {
          backgroundColor: "transparent"
        },

        title: {
          text: "Delivery Zeiten in Minuten für Zeiten über 5min",
        },

        yAxis: {
          title: {
            text: "Minuten",
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
            pointStart: 2010,
          },
        },

        series: [
          {
            name: "To Kitchen",
            data: [],
          },
          {
            name: "To Delivery",
            data: [],
          },
        ],
      },
    };
  },

  components: {
    Layout,
    highcharts: Chart,
  },

  watch: {
    startTime: function () {
      this.loadAllCharts();
    },
    endTime: function () {
      this.loadAllCharts();
    },
  },

  created() {
    this.startTime.setDate(this.endTime.getDate() - 7);
    this.loadAllCharts();
  },

  methods: {
    loadAllCharts: function () {
      this.loadArticleUsage();
      this.loadOrderState();
      this.loadOrderTimes();
    },
    setFilter: function (type) {
      let startToday = (this.startTime = new Date(new Date().setHours(0, 0, 0, 0)));
      let endToday = (this.endTime = new Date(new Date().setHours(23, 59, 59, 999)));

      switch (type) {
        case "lasthour":
          this.endTime = new Date();
          this.startTime = new Date(new Date().setHours(new Date().getHours() - 1));
          break;

        case "today":
          this.startTime = startToday;
          this.endTime = endToday;
          break;

        case "yesterday":
          this.endTime = new Date(endToday.setDate(endToday.getDate() - 1));
          this.startTime = new Date(startToday.setDate(new Date().getDate() - 1));
          break;

        case "feboreyesterday":
          this.endTime = new Date(endToday.setDate(endToday.getDate() - 2));
          this.startTime = new Date(startToday.setDate(new Date().getDate() - 2));
          break;

        case "last2days":
          this.endTime = endToday;
          this.startTime = new Date(startToday.setDate(new Date().getDate() - 1));
          break;

        case "last3days":
          this.endTime = endToday;
          this.startTime = new Date(startToday.setDate(new Date().getDate() - 2));
          break;

        default:
      }
      this.loadAllCharts();
    },
    getUTCDate: function (dt) {
      var time = dt.getTime();
      var offset = dt.getTimezoneOffset();
      offset = offset * 60000;
      return time + offset;
    },
    addFilterParams: function () {
      return `start=${this.getUTCDate(this.startTime)}&end=${this.getUTCDate(this.endTime)}`;
    },
    loadArticleUsage: function () {
      axios
        .get(`/api/reports/articleusage?${this.addFilterParams()}`)
        .then((response) => {
          this.essenChartOptions = JSON.parse(JSON.stringify(this.defaultBarOptions));
          this.trinkenChartOptions = JSON.parse(JSON.stringify(this.defaultBarOptions));
          this.trinkenChartOptions.title.text = "Buffet";
          this.trinkenChartOptions.chart.height = 800;
          if (response.data && response.data.length > 0) {
            response.data.forEach((element) => {
              if (element.target === "kitchen") {
                this.essenChartOptions.xAxis.categories.push(element.name);
                this.essenChartOptions.series[0].data.push(parseInt(element.cnt));
              } else {
                this.trinkenChartOptions.xAxis.categories.push(element.name);
                this.trinkenChartOptions.series[0].data.push(parseInt(element.cnt));
              }
            });
          }
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    loadOrderState: function () {
      axios
        .get(`/api/reports/orderstates?${this.addFilterParams()}`)
        .then((response) => {
          this.orderStatesPieOptions.series[0].data = [];
          response.data.forEach((element) => {
            this.orderStatesPieOptions.series[0].data.push({
              name: element.state,
              y: element.cnt,
            });
          });
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },

    loadOrderTimes: function () {
      axios
        .get(`/api/reports/ordertimes?${this.addFilterParams()}`)
        .then((response) => {
          this.orderTimesLineOptions.series[0].data = [];
          this.orderTimesLineOptions.series[1].data = [];

          response.data.forEach((element) => {
            let createdDate = new Date(element.createdat);
            let kitchenDate = new Date(element.kitchenat);
            let deliveredDate = new Date(element.deliveredat);

            let toKitchen = Math.floor((kitchenDate - createdDate) / (1000 * 60));
            let toDelivery = Math.floor((deliveredDate - kitchenDate) / (1000 * 60));

            if (toKitchen < 0) {
              toKitchen = 0;
            }
            if (toDelivery < 0) {
              toDelivery = 0;
            }

            if (toDelivery > 5) {
              this.orderTimesLineOptions.series[0].data.push(toKitchen);
              this.orderTimesLineOptions.series[1].data.push(toDelivery);
            }
          });
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
  },
};
</script>

<style scope lang="scss">
.reports {
  //background-color: white;
}
</style>
