<template>
  <Layout>
    <div class="cnt">
      <h1>Küche / 
        <router-link to="/buffet">Büffet</router-link> /
        <router-link to="/coffee">Kaffee</router-link>
      </h1>
      <DeliveryList target="kitchen" />
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import DeliveryList from "../components/DeliveryList.vue";

export default {
  name: "about",
  components: {
    Layout, DeliveryList,
  },
  data() {
    return {};
  },
};
</script>
