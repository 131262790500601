<template>
  <v-app-bar class="AppBar elevation-2 white--text" app color="blue-grey">
    <h2 @click="$router.push({ path: '/home' })">Ordris v2.2.2</h2>
    <v-spacer></v-spacer>
    <v-btn text v-if="false"><LocaleChanger /></v-btn>
    <span>
      {{ username }}
    </span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="toggleThemeDarkMode">
          <v-icon :style="{ transform: `rotate(${$vuetify.theme.dark ? 0 : -190}deg)` }">
            {{ icons.mdiThemeLightDark }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t(($vuetify.theme.dark ? "light" : "dark") + "Mode") }}</span>
    </v-tooltip>
    <v-btn v-bind="attrs" v-on="on" icon @click="menuClick">
      <v-icon>
        {{ icons.mdiMenu }}
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { mdiMenu, mdiLogin, mdiLogout, mdiThemeLightDark } from "@mdi/js";
import LocaleChanger from "../components/LocaleChanger";

export default {
  name: "AppBar",
  components: {
    LocaleChanger,
  },
  data() {
    return {
      icons: { mdiMenu, mdiLogin, mdiLogout, mdiThemeLightDark },
      attrs: null,
      on: null,
    };
  },
  props: {
    menuClick: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState("User", ["user"]),
    isLoginPage() {
      return this.$router.currentRoute.name === "login";
    },
    username() {
      if (localStorage.user) {
        let u = JSON.parse(localStorage.getItem("user"));
        return `${u.firstName} ${u.lastName}`;
      }
      return "";
    },
  },
  methods: {
    login() {
      this.$router.push({ path: "login" });
    },
    logout() {
      this.$store.dispatch("User/logout").then(() => this.$router.push({ path: "home" }));
    },
    toggleThemeDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style lang="scss">
.AppBar {
  h2 {
    cursor: pointer;
    text-transform: uppercase;
  }
}
</style>
