<template>
  <Layout>
    <v-container>
      <v-card class="elevation-12">
        <v-toolbar dark color="blue-grey">
          <v-toolbar-title>{{ $t("login.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-if="users.length > 0">
            <v-select
              :items="users"
              prepend-icon="person"
              name="username"
              v-model="usr.username"
              :label="$t('login.username')"
              item-text="`${data.item.firstName} ${data.item.lastName}`"
              item-value="username"
            >
              <template slot="selection" slot-scope="data"> {{ data.item.firstName }} {{ data.item.lastName }} </template>
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title v-html="`${data.item.firstName} ${data.item.lastName}`"> </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
            <v-text-field
              prepend-icon="lock"
              name="password"
              v-model="usr.password"
              :label="$t('login.password')"
              id="password"
              type="password"
              @keyup.native.enter="login()"
            >
            </v-text-field>
          </v-form>
          <div v-else>
            Loading...
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="login">{{ $t("login.login") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import authMixin from "../utils/auth";
import EventBus from "../utils/event-bus.js";

export default {
  data: () => ({
    drawer: null,
    usr: {},
    users: [],
  }),
  components: {
    Layout,
  },
  props: {
    source: String,
  },
  mixins: [authMixin],
  created() {
    this.loadUsers();
  },
  methods: {
    loadUsers: function () {
      axios
        .get(`/api/users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
    },
    login: function () {
      var that = this;
      this.doLogin(this.usr)
        .then(function (user) {
          if (user && user.username && user.username === that.usr.username) {
            localStorage.setItem('user', JSON.stringify(user));
            EventBus.$emit("setUser");

            EventBus.$emit("showMessage", `You logged in as ${user.username}`, 2000);

            if (user.tables.length === 0) {
              that.$router.push("/tables");
            } else {
              that.$router.push("/");
            }
          } else {
            EventBus.$emit("showMessage", `Could not log in.`, 2000);
          }
        })
        .catch(function (err) {
          EventBus.$emit("showMessage", `Could not log in. ${err}`);
        });
    },
  },
};
</script>
