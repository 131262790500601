<template>
  <Layout>
    <v-tabs v-model="tab"
            align-with-title>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab>Start</v-tab>
      <v-tab>Tische</v-tab>
      <v-tab>Zusammenfassung</v-tab>
      <v-tab @click="$router.push('/orders')">Offene Bestellungen</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="hello cnt">
          <h1>{{ $t("dashboard.title") }}</h1>
          <p>{{ $t("dashboard.info") }}</p>
          <v-switch label="Nicht mehr anzeigen"
                    v-model="skipHome"
                    value="yes"></v-switch>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="hello cnt">
          <v-row>
            <v-col cols="12"
                   :sm="tableCols"
                   v-for="t in tables"
                   :key="t.id">
              <button class="tbl ma-2"
                      @click="createTable(t.id)">
                {{ t.name }}
              </button>
            </v-col>
          </v-row>

          Number of columns:
          <v-slider hint="Number of columns"
                    max="12"
                    min="1"
                    v-model="tableCols"></v-slider>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="hello cnt">
          <div class="statBlock"
               @click="$router.push('/orders')">
            <h2>{{ $t("dashboard.overviewTitle") }}</h2>
            <div class="graph">
              <v-progress-circular :size="50"
                                   :width="15"
                                   :rotate="-90"
                                   :value="(summaryData.ordersUser.total / summaryData.orders.total) * 100"
                                   color="teal" />
            </div>
            <span class="txt">
              {{
                $t("dashboard.totalDesc", {
                  total: summaryData.orders.total,
                  myTotal: summaryData.ordersUser.total,
                })
              }}
            </span>
          </div>

          <div v-for="(state, i) in summaryData.orderStates"
               class="statBlock"
               @click="$router.push('/orders')"
               :key="i">
            <div class="graph">
              <v-progress-circular :key="i"
                                   :size="50"
                                   :width="15"
                                   :rotate="-90"
                                   :value="(state.value / summaryData.ordersUser.total) * 100"
                                   color="blue" />
            </div>
            <span class="txt">
              {{
                $t("dashboard.typeDesc", {
                  count: state.value,
                  type: $t("labels.states." + state.name),
                })
              }}
            </span>
          </div>

          <v-btn @click="$router.push('/orders')"
                 color="primary"
                 style="float: right">Zu den Bestellungen</v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </Layout>
</template>

<script>
import Layout from "../layouts/default.vue";
import axios from "axios";
import EventBus from "../utils/event-bus.js";

export default {
  name: "Home",
  components: {
    Layout,
  },
  data() {
    return {
      summaryData: {
        orders: { total: 0 },
        ordersUser: { total: 0 },
        orderStates: [],
      },
      session: {},
      tab: 0,
      tables: [],
      skipHome: false,
      tableCols: 4,
    };
  },
  mounted() {
    this.initialize();
    if (localStorage.skipHome) {
      this.skipHome = localStorage.getItem("skipHome");
    }
    if (this.skipHome && this.skipHome == "yes") {
      this.tab = 1;
    }

    if (localStorage.tableCols) {
      this.tableCols = localStorage.getItem("tableCols");
    }
  },
  watch: {
    skipHome(val) {
      localStorage.setItem("skipHome", val);
    },
    tableCols(val) {
      localStorage.setItem("tableCols", val);
    },
  },
  methods: {
    initialize: function () {
      axios
        .get(`/api/session`, { withCredentials: true })
        .then((response) => {
          this.session = response.data;
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
      axios
        .get(`/api/reports/summary`, { withCredentials: true })
        .then((response) => {
          this.summaryData = response.data;
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
      axios
        .get(`/api/tables?filter=byUserTable`, { withCredentials: true })
        .then((response) => {
          this.tables = response.data;
        })
        .catch((e) => {
          console.log(e);
          EventBus.$emit("showMessage", e);
        });
    },
    createTable: function (tableId) {
      axios
        .post(`/api/orders`, {
          tableId: tableId,
        })
        .then((order) => {
          this.$router.push(`/orders/${order.data.id}`);
        })
        .catch((e) => {
          EventBus.$emit("showMessage", e);
        });
    },
  },
};
</script>

<style lang="scss">
.statBlock {
  clear: both;

  .graph {
    float: left;
  }

  .txt {
    line-height: 50px;
    padding-left: 10px;
  }
}

.tbl {
  text-align: center;
  border: 1px solid grey;
  background-color: silver;
  border-radius: 5px;
  vertical-align: bottom;
  line-height: 2rem;
  font-weight: bold;
  display: block;
  width: 100%;
  font-size: xx-large;
  color: teal;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.14);
}
</style>
