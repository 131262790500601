<template>
  <router-view />
</template>

<script>
import authMixin from "./utils/auth";
export default {
  name: "App",
  mixins: [authMixin],
  created() {
    this.check();
  },
  watch: {
    $route: function () {
      this.check();
    },
  },
  methods: {
    check: function () {
      this.checkIfLogged()
        .then((response) => {
          this.user = response || null;
          if (this.user && this.user.id) {
            if (this.$route.path === "/login") {
              this.$router.push("/");
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(() => {
          this.$router.push("/error");
        });
    },
  },
};
</script>

<style lang="scss">
html {
  // vuetify reset
  overflow-y: auto !important;
  overflow-x: auto !important;

  .cnt {
    padding: 50px;
  }
}

@media screen and (max-width: 600px) {
  html {
    .cnt {
      padding: 20px 5px;
    }
  }
}
</style>
